import { Link, useLocation, useNavigate } from '@remix-run/react';
import { onAuthStateChanged, User } from 'firebase/auth';
import { useEffect, useState } from 'react';
import { FaArrowRight } from 'react-icons/fa';
import { auth } from '~/utils/Auth/firebase-service';
import { getProfile } from '~/utils/ServerRequest';
import '../styles/home.css';
import { PopupModal } from './PopupModal';
import logo from '~/assets/blueskeyeHeaderLogo.png'
import logo_webp from '~/assets/blueskeyeHeaderLogo.webp'

export const Header = () => {
  const [signUp, setSignUp] = useState(false);
  const [login, setLogin] = useState(false);
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);
  const [allowance, setAllowance] = useState<number>();
  const location = useLocation();

  useEffect(() => {
    // Ensures user is authorised before initialising variables.
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setUser(user);
        setLogin(false);
        setSignUp(false);

        const profile = await getProfile();
        setAllowance(profile.allowance);
      } else {
        setUser(null);
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const toggleSignUp = () => {
    setSignUp(!signUp);
  };

  const toggleLogin = () => {
    setLogin(!login);
  };

  const handleLogout = () => {
    auth
      .signOut()
      .then(() => setUser(null))
      .catch((error) => {
        console.error('Error signing out:', error);
      });
  };

  let navigate = useNavigate();

  return (
    <header className="header">
      <div className="logoHeader">
        <Link to="/home">
          <img
            src={logo_webp}
            width={270}
            id="popupLogo"
            alt="BLUESKEYE AI Logo"
            onError={(e) => {
              e.currentTarget.onerror = null; // Prevents infinite loop if the fallback also fails
              e.currentTarget.src = logo; // Fallback to the original JPG or PNG
            }}
          />
        </Link>
      </div>

      {location.pathname === '/home' && (
        <>
          <div className="centeredButtons">
            <button className="linkButton">Pricing</button>
            <button className="linkButton">Support</button>
            <button className="linkButton">Contact</button>
          </div>
          <div className="rightButtons">
            {user === null ? (
              <>
                <button className="headerButton" onClick={toggleLogin}>
                  Log In
                </button>

                <button className="headerButton highlight" onClick={toggleSignUp}>
                  Sign Up <FaArrowRight />
                </button>
              </>
            ) :
              <>
                <button className="headerButton highlight" onClick={() => navigate("/uploads")}>
                  Uploads
                </button>

                <button className="headerButton" onClick={handleLogout}>
                  Logout
                </button>

              </>}
          </div>
        </>

      )}

      {loading || (location.pathname === '/uploads/projects' && (
        <div className="rightButtons">
          {user === null ? (
            <>
              <button className="headerButton" onClick={toggleLogin}>
                Log In
              </button>

              <button className="headerButton highlight" onClick={toggleSignUp}>
                Sign Up <FaArrowRight />
              </button>
            </>
          ) : (
            <div className="alignItemsCenter">
              <button
                className="clearButton headerButtonText"
                onClick={() => { }}
              >
                {allowance} tokens
              </button>
              <p>signed in as: </p>
              <button
                className="clearButton headerButtonText"
                onClick={() => { }}
              >
                {user.displayName}
              </button>
              <button className="clearButton" onClick={handleLogout}>
                Logout
              </button>
            </div>
          )}
        </div>
      ))}

      {signUp && (
        <PopupModal
          mode={'signUp'}
          toggle={toggleSignUp}
          switchModal={() => {
            toggleSignUp();
            toggleLogin();
          }}
        />
      )}
      {login && (
        <PopupModal
          mode={'login'}
          toggle={toggleLogin}
          switchModal={() => {
            toggleSignUp();
            toggleLogin();
          }}
        />
      )}
    </header>
  );
};
