import { ActionFunctionArgs } from '@remix-run/node';
import { Link, useNavigate } from '@remix-run/react';
import { sendSignInLinkToEmail, signOut } from 'firebase/auth';
import { useEffect, useState } from 'react';
import { auth } from '~/utils/Auth/firebase-service';
import {
  actionCodeSettings,
  loginWithEmailLink,
  loginWithGoogle,
} from '~/utils/Auth/signInMethods';
import logo from '../assets/blueskeyeHeaderLogo.png';
import '../styles/modal.css';

export async function action({ request }: ActionFunctionArgs) {
  const formData = await request.formData();

  const email = String(formData.get('email')).toLowerCase();

  // placed here in case there is data left over from a previously authenticated user
  await signOut(auth);

  try {
    if (email) {
      await sendSignInLinkToEmail(
        auth,
        email,
        actionCodeSettings
      );
    }
  } catch (error) {
    console.error(error);
  }
}

export const PopupModal = (props: any) => {
  const [email, setEmail] = useState('');
  const [emailInvalid, setEmailInvalid] = useState<boolean | null>(null);
  const [agreed, setAgreed] = useState<boolean | null>(null);
  const [clickedButton, setClickedButton] = useState<string>('');
  const [magicLinkSent, setMagicLinkSent] = useState(false);
  const navigate = useNavigate();

  const isEmailValid = (email: string) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    if (emailRegex.test(email)) {
      return true;
    } else {
      return false;
    }
  };

  async function handleFormSubmit(e: any) {
    e.preventDefault();

    if (clickedButton == 'create' || clickedButton == 'signIn') {
      if (isEmailValid(email)) {
        // props.toggle();
        setEmailInvalid(false);

        var agreeCheckbox = document.getElementsByName(`_agree`);
        if (
          props.mode != 'signUp' ||
          (agreeCheckbox[0] as HTMLInputElement).checked
        ) {
          setAgreed(true);

          loginWithEmailLink(email).then(() => setMagicLinkSent(true));
        } else {
          setAgreed(false);
        }
      } else {
        setEmailInvalid(true);
      }
    } else if (clickedButton == 'google') {
      var agreeCheckbox = document.getElementsByName(`_agree`);
      if (
        props.mode != 'signUp' ||
        (agreeCheckbox[0] as HTMLInputElement).checked
      ) {
        setAgreed(true);
        let success = await loginWithGoogle();

        if (success) {
          navigate('/uploads');
        }
      } else {
        setAgreed(false);
      }
    } else {
      console.error('Unknown login type');
    }
  }

  useEffect(() => {
    const close = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        props.toggle();
      }
    };

    window.addEventListener('keydown', close);

    return () => window.removeEventListener('keydown', close);
  }, []);

  return (
    <div className="popup">
      <div className="modal-backdrop" onClick={() => props.toggle()}></div>
      <div className="popup-inner">
        <div className="rowArrangement" id="topAccountRow">
          <p className="boldText">
            {props.mode == 'signUp'
              ? `Already have an account?`
              : `Don't have an account?`}
          </p>
          <button id="loginButton" onClick={() => props.switchModal()}>
            {props.mode == 'signUp' ? `Log In` : `Sign Up`}
          </button>
        </div>
        <div id="popupHeadline">
          <div id="popupLogoHeader">
            <img src={logo} width={270} id="popupLogo" alt="BLUESKEYE AI Logo" />
            <p className="logoText" id="popupLogoHeaderText">
              Expressed Behaviour Analytics Platform
            </p>
          </div>
          <p>Capture incredible new insights by exploring user expressions</p>
        </div>
        {!magicLinkSent ? (
          <div id="formSection">
            <form onSubmit={handleFormSubmit} id="popupForm">
              {emailInvalid !== true ? (
                <input
                  id="emailInput"
                  type="text"
                  name="email"
                  value={email}
                  placeholder="Email"
                  onChange={(e) => setEmail(e.target.value)}
                />
              ) : (
                <>
                  <input
                    id="emailInvalidInput"
                    type="text"
                    value={email}
                    placeholder="Email"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <p className="redText" id="invalidEmailText">
                    Invalid email. Please ensure your email is correct.
                  </p>
                </>
              )}
              {props.mode == 'signUp' && (
                <div className="rowArrangement" id="agreeSection">
                  <input
                    type="checkbox"
                    name={`_agree`}
                    id="agreeCheckbox"
                    value={'unchecked'}
                  />
                  <p>
                    I agree to <span className="boldText">BLUESKEYE AI</span>'s
                    <Link className="policyLink boldText" to={'/privacyPolicy'}>
                      {' '}
                      {/* #TODO: change links */}
                      Terms of Service
                    </Link>
                    ,{' '}
                    <Link className="policyLink boldText" to={'/privacyPolicy'}>
                      Privacy Policy
                    </Link>{' '}
                    and{' '}
                    <Link className="policyLink boldText" to={'/privacyPolicy'}>
                      Data Processing Agreement
                    </Link>
                    .
                  </p>
                </div>
              )}
              {props.mode == 'signUp' && agreed == false && (
                <p className="redText">
                  Please check the box above to proceed.
                </p>
              )}
              {props.mode == 'signUp' ? (
                <button
                  type="submit"
                  id="createAccountButton"
                  onClick={() => setClickedButton('create')}
                >
                  Create my account
                </button>
              ) : (
                <button
                  type="submit"
                  id="createAccountButton"
                  onClick={() => setClickedButton('signIn')}
                >
                  Sign In
                </button>
              )}
              <button
                type="submit"
                name="google-signUp"
                id="googleButton"
                onClick={() => setClickedButton('google')}
              >
                {props.mode == 'signUp'
                  ? `Sign up with Google`
                  : `Sign in with Google`}
              </button>
            </form>
            <button id="cancelButton" onClick={props.toggle}>
              Cancel
            </button>
          </div>
        ) : (
          <div id="formSection">
            <p id="loginSentText">
              Login link sent to <span className="boldText">{email}</span>
            </p>
            <p className="boldText" id="loginCheckText">
              Please check your email for a login link. If you have not received
              anything, please check your spam folder.
            </p>
            <p className="boldText" id="loginCheckText">
              Still having problems?{' '}
              <button
                className="boldText"
                id="newLinkButton"
                onClick={() => setMagicLinkSent(false)}
              >
                Request a new link.
              </button>
            </p>
          </div>
        )}
      </div>
    </div>
  );
};
